import { Avatar } from './avatar';
import React, { ReactElement } from 'react';

interface PostInfoProps {
  author: string;
  date: string;
  timeToRead: number;
}
export const PostInfo = ({ author, date, timeToRead }: PostInfoProps): ReactElement => (
  <div className="flex text-gray-400 items-center gap-3">
    <Avatar className="w-10 h-10" />
    <p className="text-xs">
      <b>{author}</b> &bull; Posted on {date} &bull; {Math.round(timeToRead)} mins
    </p>
  </div>
);
