import React from 'react';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import SEO from '../components/seo';
import { H1, TextLink, TextLinkVariant } from '../components/design';
import { withErrorBoundary } from '../components/error-boundary';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Mdx, SiteSiteMetadata } from '../generated/graphql.d';
import { DeepRequired } from '../utils/deep-required';
import { kebabCase } from '../utils/kebab-case';
import { TagList } from '../components/tag-list';
import { PillVariant } from '../components/pills/pill';
import { PostInfo } from '../components/post-info';
import SimpleLayout from '../components/layouts/simple-layout';
import { ClassicShareLinks } from '../components/share-links/classic-share-links';

export const pageQuery = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      timeToRead
      fields {
        slug
      }
      frontmatter {
        date(formatString: "DD MMM YYYY")
        title
        description
        tags
        category
        thumbnail {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 160, height: 160)
          }
        }
        hackerNews
      }
    }
  }
`;

interface BlogPostTemplateProps {
  data: {
    mdx: DeepRequired<Mdx>;
    site: SiteSiteMetadata;
  };
  pageContext: {
    next: DeepRequired<Mdx>;
    previous: DeepRequired<Mdx>;
  };
}

const BlogPostTemplate: React.FC<BlogPostTemplateProps> = withErrorBoundary({
  scope: 'blog-post-template',
})(({ data, pageContext: { next, previous } }) => {
  const {
    mdx: {
      body,
      timeToRead,
      frontmatter: { title, date, description, thumbnail, tags, category, hackerNews },
      fields: { slug },
    },
    site: { siteUrl = 'https://piotrstaniow.pl' },
  } = data;
  const currentUrl = `${siteUrl}${slug}`;
  const twitterLink = `https://mobile.twitter.com/search?q=${currentUrl}`;
  const twitterCard = `${currentUrl}/twitter-card.jpg`;

  return (
    <SimpleLayout>
      <SEO
        title={title}
        description={description}
        meta={[{ name: 'twitter:image', content: twitterCard }]}
      />
      <article className="blog-post text-gray-700 lg:w-9/12">
        <header className="flex flex-col justify-between gap-4">
          <section className="flex flex-col lg:flex-row items-center mb-6">
            <div className="w-16 h-16 lg:w-32 lg:h-32 block my-6 mr-5">
              <GatsbyImage
                image={thumbnail.childImageSharp.gatsbyImageData}
                alt="Post icon"
                className="lg:w-32 lg:h-32"
                aria-hidden
              />
            </div>
            <H1 withMargins={false}>{title}</H1>
          </section>
          <hr className="border-gray-300" />
          <section>
            <div className="flex flex-col items-start gap-4 sm:flex-row sm:justify-between sm:items-center">
              <PostInfo author="Piotr Staniów" date={date} timeToRead={timeToRead} />
              <TagList tags={tags} variant={PillVariant.BOLD} />
            </div>
            {category && (
              <p className="text-sm text-gray-400 mt-4">
                This article is a part of{' '}
                <TextLink
                  as={Link}
                  to={`/blog/categories/${kebabCase(category)}`}
                  className="font-medium"
                >
                  {category}
                </TextLink>{' '}
                series.
              </p>
            )}
          </section>
        </header>
        <div className="my-6 sm:my-10">
          <MDXRenderer>{body}</MDXRenderer>
        </div>
        <footer className="py-4 flex flex-col items-end">
          <ClassicShareLinks url={currentUrl} />
          <p className="py-1 text-xs">
            Join the discussion on{' '}
            <TextLink variant={TextLinkVariant.CTA} href={twitterLink}>
              Twitter
            </TextLink>
            {hackerNews && (
              <>
                {' '}
                and{' '}
                <TextLink variant={TextLinkVariant.CTA} href={hackerNews}>
                  Hacker News
                </TextLink>
              </>
            )}
          </p>
          <section className="my-6 grid grid-cols-1 sm:grid-cols-2 w-full gap-24">
            {previous && (
              <Link
                to={previous.fields.slug!}
                className="sm:col-start-1 sm:col-end-1 hover:text-main-light"
              >
                <span className="py-4">&laquo; Newer post</span>
                <p className="font-medium font-sans text-lg my-4">{previous.frontmatter.title}</p>
                <p className="text-sm mb-3 font-body">{previous.frontmatter.description}</p>
              </Link>
            )}
            {next && (
              <Link
                to={next.fields.slug!}
                className="sm:col-start-2 sm:col-end-2 hover:text-main-light text-right"
              >
                <span className="py-4 text-sm capitalize">Older post &raquo;</span>
                <p className="font-medium font-sans text-lg my-4">{next.frontmatter.title}</p>
                <p className="text-sm mb-3 font-body">{next.frontmatter.description}</p>
              </Link>
            )}
          </section>
        </footer>
      </article>
    </SimpleLayout>
  );
});
export default BlogPostTemplate;
