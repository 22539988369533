import React from 'react';
import { Pills, PillsProps } from './pills';

interface TagListProps {
  tags: string[];
  variant: PillsProps<any>['variant'];
  className?: string;
}

const spec: Pick<PillsProps<string>, 'getId' | 'getLink' | 'getText'> = {
  getId: (tag) => tag,
  getLink: (tag) => `/blog/tags/${tag.replace(/([^A-Za-z0-9]+)$/, '')}`,
  getText: (tag) => `#${tag}`,
};

export const TagList: React.FC<TagListProps> = ({ tags, variant, className }) => (
  <Pills ariaLabel="Post tags" items={tags} variant={variant} className={className} {...spec} />
);
