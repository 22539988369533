import React, { ComponentProps, ReactElement } from 'react';
import { Icon } from '@iconify/react';

interface ShareIconProps {
  icon: ComponentProps<typeof Icon>['icon'];
}

export const ShareIcon = ({ icon }: ShareIconProps): ReactElement => (
  <Icon icon={icon} className="w-10 h-10" />
);
