import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

interface AvatarProps {
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({ className }) => (
  <div className={className}>
    <StaticImage
      src="../../assets/images/avatar.png"
      alt="Photo of my chubby face smiling to you"
      className="rounded-full"
      placeholder="blurred"
      layout="constrained"
      aspectRatio={1}
      formats={['auto', 'webp', 'avif']}
    />
  </div>
);
