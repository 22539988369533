import React, { ReactElement } from 'react';
import TwitterShareButton from 'react-share/es/TwitterShareButton';
import twitter from '@iconify/icons-fa-brands/twitter-square';
import facebook from '@iconify/icons-fa-brands/facebook-square';
import reddit from '@iconify/icons-fa-brands/reddit-square';
import { FacebookShareButton, RedditShareButton } from 'react-share';
import { ShareIcon } from './share-icon';

interface Props {
  url: string;
}

export const ClassicShareLinks = ({ url }: Props): ReactElement => (
  <section className="flex items-center w-full font-serif">
    <span>
      Did you like it? <em>Let others know!</em>
    </span>
    <hr className="flex-grow mx-4" />
    <ul className="flex">
      <li style={{ color: '#1DA1F2' }}>
        <TwitterShareButton url={url}>
          <ShareIcon icon={twitter} />
        </TwitterShareButton>
      </li>
      <li style={{ color: '#4267B2' }}>
        <FacebookShareButton url={url}>
          <ShareIcon icon={facebook} />
        </FacebookShareButton>
      </li>
      <li style={{ color: '#FF4500' }}>
        <RedditShareButton url={url}>
          <ShareIcon icon={reddit} />
        </RedditShareButton>
      </li>
    </ul>
  </section>
);
